import React from 'react';
import Iframe from 'react-iframe'
//import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';



//import Navbar from './Navbar';

Amplify.configure(awsconfig);

function SystemManual() {

    return (
        <div>
          <Iframe id = 'manual'
                    url = 'http://manual-k.jukendoctor.org'
                    position='absolute'
                    width='100%'
                    height='100%'/>
        </div>
    )
}

export default SystemManual