import React, {useState, useEffect, useRef} from 'react';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import { Stage, Layer, Line } from "react-konva";
import styled from "styled-components";

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listPostsSortByStartDate } from '../graphql/queries';
import { updateSchedules, deleteSchedules } from '../graphql/mutations';

import {
  useParams
} from 'react-router-dom';

Modal.setAppElement("#root");

Amplify.configure(awsconfig);

function Student() {
  let { scode } = useParams();

   const [schedules, setSchedules] = useState([]);
   const [student, setStudent] = useState([]);
   const [scheduleId, setScheduleId] = useState();
   const [scheduleMemo, setScheduleMemo] = useState("");
   const [scheduleDate, setScheduleDate] = useState("");

   let month = parseInt(moment().format('YYYYMM'));

    useEffect(() => {
      async function fetchScheduleAsync() {
        const user = await Auth.currentAuthenticatedUser();
        const tcode = user.attributes['custom:tcode'];
        const results = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
          filter: {scode: {eq: scode}, status: {ne: 1}}})));

        let results3 = results.data.listPostsSortByStartDate.items;
        //filter: {scode: {eq: scode}, month: {eq: month}}
        let nextToken = results.data.listPostsSortByStartDate.nextToken;
        while(nextToken) {
          const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
            filter: {scode: {eq: scode}, status: {ne: 1}}
          , nextToken: nextToken})));
  
          results3 = results3.concat(results2.data.listPostsSortByStartDate.items);

          nextToken = results2.data.listPostsSortByStartDate.nextToken;
        }

      /*
      if (results.data.listPostsSortByStartDate.nextToken) {


        const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
          filter: {scode: {eq: scode}, status: {ne: 1}}
        ,limit: 5000, nextToken: results.data.listPostsSortByStartDate.nextToken})));

        results3 = results.data.listPostsSortByStartDate.items.concat(results2.data.listPostsSortByStartDate.items);
      }
      */
      //const results3 = {...results, ...results2};
      if (results3) {
        results.data.listPostsSortByStartDate.items = results3;
      }

        setSchedules(results.data.listPostsSortByStartDate.items.filter((_, i) => i !== results.data.listPostsSortByStartDate.items.length));

        let apiName = 'jd';
        let path = '/sales';
        let myInit = {
          headers: {},
          response: true,
          queryStringParameters: {
            "tcode": tcode,
            "scode": scode
          }
        }
        const resultsScode = await API.get(apiName, path, myInit);   
        setStudent(resultsScode.data.Students[0]);
      }
      fetchScheduleAsync();
    },[scode,month]);

    async function deleteSchedule(id) {
      const delSchedule = await API.graphql(graphqlOperation(deleteSchedules, {input: {'id': id}}));
      console.log(delSchedule);
      const user = await Auth.currentAuthenticatedUser();
      const tcode = user.attributes['custom:tcode'];
      const results = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
        filter: {scode: {eq: scode}, status: {ne: 1}}})));
      //filter: {scode: {eq: scode}, month: {eq: month}}
      //console.log(results);

      let results3 = results.data.listPostsSortByStartDate.items;
        //filter: {scode: {eq: scode}, month: {eq: month}}
        let nextToken = results.data.listPostsSortByStartDate.nextToken;
        while(nextToken) {
          const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
            filter: {scode: {eq: scode}, status: {ne: 1}}
          , nextToken: nextToken})));
  
          results3 = results3.concat(results2.data.listPostsSortByStartDate.items);

          nextToken = results2.data.listPostsSortByStartDate.nextToken;
        }
      //const results3 = {...results, ...results2};
      if (results3) {
        results.data.listPostsSortByStartDate.items = results3;
      }

        setSchedules(results.data.listPostsSortByStartDate.items.filter((_, i) => i !== results.data.listPostsSortByStartDate.items.length));
    }

    const updateScheduleInput = {
      id: null,
      status: 0,
    }
    function setAttendance(id,st) {
      updateSchedule(id,st,null);
    }
    async function updateSchedule(id,st,signature) {
      updateScheduleInput['id'] = id;
      updateScheduleInput['status'] = st;
      if (signature) {
        updateScheduleInput['signature'] = signature;
      }
      if (st === 1) {
        updateScheduleInput['memo'] = document.getElementById('memo-st-1').value;
      } 
      const updateSchedule = await API.graphql(graphqlOperation(updateSchedules, {input: updateScheduleInput}));
      console.log(updateSchedule.data.updateSchedules.id)
      let apiName = 'sales';
      let path = '/request';
      let myInit = {
        headers: {},
        response: true,
        queryStringParameters: {'id': updateSchedule.data.updateSchedules.id }
      }
      console.log(myInit);

      if (st === 1) {
        const resultsScode = await API.get(apiName, path, myInit);
        console.log(resultsScode);
      }

      const tcode = document.getElementById('teacher').dataset.tcode;
      const results = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
        //filter: {scode: {eq: scode}}
        filter: {scode: {eq: scode}, status: {ne: 1}}})));
      //filter: {scode: {eq: scode}, month: {eq: month}}
      let results3 = results.data.listPostsSortByStartDate.items;
        //filter: {scode: {eq: scode}, month: {eq: month}}
        let nextToken = results.data.listPostsSortByStartDate.nextToken;
        while(nextToken) {
          const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
            filter: {scode: {eq: scode}, status: {ne: 1}}
          , nextToken: nextToken})));
  
          results3 = results3.concat(results2.data.listPostsSortByStartDate.items);

          nextToken = results2.data.listPostsSortByStartDate.nextToken;
        }
      //const results3 = {...results, ...results2};
      if (results3) {
        results.data.listPostsSortByStartDate.items = results3;
      }

        setSchedules(results.data.listPostsSortByStartDate.items.filter((_, i) => i !== results.data.listPostsSortByStartDate.items.length));
    }

    const [modalIsOpenIn, setIsOpenIn] = React.useState(false);
    const [modalIsOpenDelete, setIsOpenDelete] = React.useState(false);
    const modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.85)"
      },
      content: {
        margin: "0 auto 0 auto",
        top: "5rem",
        bottom: "5rem",
        borderRadius: "1rem",
        padding: "75px 1rem 0 1rem",
        height: "400px",
        maxWidth: "350px",
        width: "80%"
      }
    };

    const modalStyleDelete = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.85)"
      },
      content: {
        margin: "0 auto 0 auto",
        top: "5rem",
        bottom: "5rem",
        borderRadius: "1rem",
        padding: "75px 1rem 0 1rem",
        height: "200px",
        maxWidth: "350px",
        width: "80%"
      }
    };




 const tool = "pen";
 const size = 5;
 const color = "#000";
 const [lines, setLines] = useState([]);
 const isDrawing = useRef(false);
 const stageRef = useRef();

 const handleMouseDown = (e) => {
   isDrawing.current = true;
   const pos = e.target.getStage().getPointerPosition();
   setLines([
     ...lines,
     {
       tool,
       points: [pos.x, pos.y],
       color,
       size
     }
   ]);
 };

 const handleMouseMove = (e) => {
   // no drawing - skipping
   if (!isDrawing.current) {
     return;
   }
   const stage = e.target.getStage();
   const point = stage.getPointerPosition();
   let lastLine = lines[lines.length - 1];
   lastLine.points = lastLine.points.concat([point.x, point.y]);
   lines.splice(lines.length - 1, 1, lastLine);
   setLines(lines.concat());
 };

 const handleMouseUp = () => {
   isDrawing.current = false;
 };

 const [modalIsOpenAgree, setIsOpenAgree] = React.useState(false);

  const modalStyleAgree = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.85)"
    },
    content: {
      margin: "0 auto 0 auto",
      top: "1rem",
      bottom: "1rem",
      left: "0rem",
      right: "0rem",
      borderRadius: "4px",
      padding: "5px 5px 5px 5px",
      height: "520px",
      maxWidth: "320px",
      width: "320px"
    }
  };

  function setAttendanceAgree(id,st, agreeImage) {
    updateSchedule(id,st, agreeImage);
  }

    return (
        <div>
          <ul className={ 'menu-list' }>
        <li><a href="/">予定一覧</a></li>
              <li><a href="/students">担当生徒</a></li>
            </ul>
        <div className="wrapper">

  
    <h2>授業一覧</h2>
    <div className="separate">
    <div className="salesBtn">
      <a href={ '/applied/' + scode }><p>完了一覧</p></a>
    </div>
    <div className="addBtn">
      <a href={ '/schedule/' + scode }><p>授業登録</p></a>
    </div>
  </div>
  <div className="parsonalOut">
    <p>{ student.sname }（{ student.furi }）</p>
    <p>{ student.address1 }{ student.address2 }{ student.building }</p>
  </div>
  <div className="tableContainer -personal">
    <table>
      <thead>
        <tr>
          <th className="del-col"></th>
          <th className="def-col">授業日</th>
          <th className="def-col">時間</th>
        </tr>
      </thead>
      <tbody>
        { schedules.map((schedule) => (
          <tr key={schedule.id}>
          <td><FontAwesomeIcon icon={faTrash} size="2x" onClick={ () => {setScheduleId(schedule.id); setIsOpenDelete(true);}} style={{ display: schedule.status===0 ? 'initial' : 'none' }}/></td>
          <td><p>{ moment(schedule.lesson_date).format('YYYY年MM月DD日') }</p>
          <button onClick={() => {setScheduleId(schedule.id); setScheduleDate(moment(schedule.lesson_date).format('YYYY年MM月DD日') + ' ' + moment(schedule.lesson_start).format('HH:mm') + '-' + moment(schedule.lesson_end).format('HH:mm')); setIsOpenAgree(true);}} className={ 'btn-status in' } style={{ display: schedule.status!==2 ? 'initial' : 'none' }}>サイン</button>
          <button className={ 'btn-status sign' } style={{ display: schedule.status===2 ? 'initial' : 'none' }}>サイン</button>
          </td>
          <td><p>{ moment(schedule.lesson_start).format('HH:mm') }〜{ moment(schedule.lesson_end).format('HH:mm') }</p>
          <button onClick={() => {setScheduleId(schedule.id); setScheduleMemo(schedule.memo); setIsOpenIn(true);}} className={ 'btn-status' } style={{ display: schedule.status!==1 ? 'initial' : 'none' }}>予約</button>
            <button className={ 'btn-status out' } style={{ display: schedule.status===1 ? 'initial' : 'none' }} disabled="disabled">完了</button>
          </td>
        </tr>
        )) }
      </tbody>
    </table>
  </div>
  </div>

      <Modal isOpen={modalIsOpenDelete} style={modalStyleDelete}>
        <button className="btn-close" onClick={() => setIsOpenDelete(false)}>×</button>
        <p>授業の予約を削除します。</p>
        <button className="btn-out" onClick={() => { deleteSchedule(scheduleId); setIsOpenDelete(false); } }>削除</button>
      </Modal>

      <Modal isOpen={modalIsOpenIn} style={modalStyle}>
        <button className="btn-close" onClick={() => setIsOpenIn(false)}>×</button>
        <textarea id="memo-st-1" defaultValue={ scheduleMemo }></textarea>
        <button className="btn-in" onClick={() => { setAttendance(scheduleId,1); setIsOpenIn(false); } }>完了</button>
      </Modal>



      <Modal isOpen={modalIsOpenAgree} style={modalStyleAgree}>
      <button className="btn-close" onClick={() => { setLines([]); setIsOpenAgree(false);} }>×</button>
      <div>
          <p>{ student.sname }様</p><p>{ scheduleDate }</p>
        </div>
      <div>
      <>
          <FlexDiv>
            <StageDiv>
              <Stage
                width={300}
                height={350}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                onMousemove={handleMouseMove}
                onTouchMove={handleMouseMove}
                onMouseup={handleMouseUp}
                onTouchEnd={handleMouseUp}
                style={{
                  border: "1px solid",
                  marginTop: "30px"
                }}
                ref={stageRef}
              >
                <Layer>
                  {lines.map((line, i) => (
                    <Line
                      key={i}
                      points={line.points}
                      stroke={line.color}
                      strokeWidth={line.size}
                      tension={0.5}
                      lineCap="round"
                      globalCompositeOperation={
                        line.tool === "eraser" ? "destination-out" : "source-over"
                      }
                    />
                  ))}
                </Layer>
              </Stage>
            </StageDiv>
            
          </FlexDiv>
        </>
        </div>
        <button
        id="save"
        onClick={() => {
          setAttendanceAgree(scheduleId,2,stageRef.current.toDataURL());
          //console.log(stageRef.current.toDataURL());
          /*
          downloadURI(
            stageRef.current
              .getStage()
              .toDataURL({ mimeType: "image/png", quality: 1.0 }),
            "export_" + formatDate(new Date(), "yyyyMMddHHmmssSSS") + ".png"
          );*/ setLines([]); setIsOpenAgree(false);
        }}
        className="btn-in">承認</button>
        </Modal>


        </div>
    )
}

/*
async function getNextToken(token, tcode, scode, results) {
  console.log(token)

  const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
    filter: {scode: {eq: scode}, status: {ne: 1}}
  ,limit: 5000, nextToken: token})));

  const results3 = results.data.listPostsSortByStartDate.items.concat(results2.data.listPostsSortByStartDate.items);

  if (results2.data.listPostsSortByStartDate.nextToken) {
    getNextToken(results2.data.listPostsSortByStartDate.nextToken,tcode, scode, results)
  }

  console.log(results3);
}
*/
export default Student

const FlexDiv = styled.div`
  display: flex;
`;

const StageDiv = styled.div`
  background-color: #fff;
  width: 300px;
  height: 390px;
  margin: 0px auto 5px auto;
`;