import React,　{useState, useEffect, useRef} from 'react';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import Modal from "react-modal";

import moment from 'moment';

import { Stage, Layer, Line } from "react-konva";
import styled from "styled-components";

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { updateSchedules } from '../graphql/mutations';

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  useParams
} from 'react-router-dom';

//import Navbar from './Navbar';
Modal.setAppElement("#root");

Amplify.configure(awsconfig);

function Applied() {
  let { scode } = useParams();

   const [schedules, setSchedules] = useState([]);
   const [student, setStudent] = useState([]);
   const [scheduleId, setScheduleId] = useState();
   const [salesId, setSalesId] = useState();
   const [scheduleDate, setScheduleDate] = useState("");

   let month = moment().format('YYYY-MM');
   const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
      async function fetchScheduleAsync() {
        const user = await Auth.currentAuthenticatedUser();
        const tcode = user.attributes['custom:tcode'];

        let apiApplied = 'sales';
        let pathApplied = '/applied';
        let myInitApplied = {
          headers: {},
          response: true,
          queryStringParameters: {
            "tcode": tcode,
            "scode": scode,
            "learning": month
          }
        }
        const results = await API.get(apiApplied, pathApplied, myInitApplied);
        let s = [];
        if (results.data.Sales) {
          s = results.data.Sales.filter((_, i) => i !== results.data.Sales.length);
        }
        setSchedules(s);

        let apiName = 'jd';
        let path = '/sales';
        let myInit = {
          headers: {},
          response: true,
          queryStringParameters: {
            "tcode": tcode,
            "scode": scode,
          }
        }
        const resultsScode = await API.get(apiName, path, myInit);   
        setStudent(resultsScode.data.Students[0]);
      }
      fetchScheduleAsync();
    },[scode,month]);

    async function addMonth(i) {
      const tMonth = moment(i).format('YYYY-MM');
      const tcode = document.getElementById('teacher').dataset.tcode;
      let apiApplied = 'sales';
      let pathApplied = '/applied';
      let myInitApplied = {
        headers: {},
        response: true,
        queryStringParameters: {
          "tcode": tcode,
          "scode": scode,
          "learning": tMonth
        }
      }
      const results = await API.get(apiApplied, pathApplied, myInitApplied);
        let s = [];
        if (results.data.Sales) {
          s = results.data.Sales.filter((_, i) => i !== results.data.Sales.length);
        }
        setSchedules(s);
    }

    const updateScheduleInput = {
      id: null,
      status: 0,
    }
    async function updateSchedule(id,sales_id,signature) {
      updateScheduleInput['id'] = id;
      updateScheduleInput['status'] = 1;// 固定で1
      updateScheduleInput['signature'] = signature;

      const updateSchedule = await API.graphql(graphqlOperation(updateSchedules, {input: updateScheduleInput}));
      // sales id を渡す（updateと判断させる）
      updateSchedule.data.updateSchedules['sales_id'] = sales_id;
      // 邪魔なので画像データをnullで上書きする
      updateSchedule.data.updateSchedules['signature'] = null;
      
      let apiName = 'sales';
      let path = '/request';
      let myInit = {
        headers: {},
        response: true,
        queryStringParameters: updateSchedule.data.updateSchedules
      }
      await API.get(apiName, path, myInit);
      addMonth(month);
    }


         //const [tool, setTool] = useState("pen");
 const tool = "pen";
 //const [size, setSize] = useState(5);
 const size = 5;
 //const [color, setColor] = useState("#000000");
 const color = "#000";
 const [lines, setLines] = useState([]);
 const isDrawing = useRef(false);
 const stageRef = useRef();

 const handleMouseDown = (e) => {
   isDrawing.current = true;
   const pos = e.target.getStage().getPointerPosition();
   setLines([
     ...lines,
     {
       tool,
       points: [pos.x, pos.y],
       color,
       size
     }
   ]);
 };

 const handleMouseMove = (e) => {
   // no drawing - skipping
   if (!isDrawing.current) {
     return;
   }
   const stage = e.target.getStage();
   const point = stage.getPointerPosition();
   let lastLine = lines[lines.length - 1];
   lastLine.points = lastLine.points.concat([point.x, point.y]);
   lines.splice(lines.length - 1, 1, lastLine);
   setLines(lines.concat());
 };

 const handleMouseUp = () => {
   isDrawing.current = false;
 };

 const [modalIsOpenAgree, setIsOpenAgree] = React.useState(false);

  const modalStyleAgree = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      backgroundColor: "rgba(0,0,0,0.85)"
    },
    content: {
      margin: "0 auto 0 auto",
      top: "1rem",
      bottom: "1rem",
      left: "0rem",
      right: "0rem",
      borderRadius: "4px",
      padding: "5px 5px 5px 5px",
      height: "520px",
      maxWidth: "320px",
      width: "320px"
    }
  };

  function setAttendanceAgree(id,sales_id,agreeImage) {
    updateSchedule(id,sales_id,agreeImage);
  }
  
    return (
        <div>
          <ul className={ 'menu-list' }>
        <li><a href="/">予定一覧</a></li>
              <li><a href="/students">担当生徒</a></li>
            </ul>
        <div className="wrapper">

  
    <h2>授業一覧</h2>
    <div className="separate">
    <div className="salesBtn">
      <a href={ '/student/' + scode }><p>予約一覧</p></a>
    </div>
    <div className="addBtn">
      <a href={ '/schedule/' + scode }><p>授業登録</p></a>
    </div>
  </div>
  <div className="parsonalOut">
    <p>{ student.sname }（{ student.furi }）</p>
    <p>{ student.address1 }{ student.address2 }{ student.building }</p>
  </div>
  <div className="tableContainer -personal">
    <div className="date-picker">
  <DatePicker
      selected={startDate}
      onChange={(date) => {setStartDate(date); addMonth(date);}}
      dateFormat="yyyy/MM"
      showMonthYearPicker
    /></div>
    <table>
      <thead>
        <tr>
          <th>授業日</th>
          <th>時間</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { schedules.map((schedule) => (
          <tr key={schedule.sales_id}>
          <td>{ moment(schedule.learning_date).format('YYYY年MM月DD日') }</td>
          <td>{ moment(schedule.learning_date + ' ' + schedule.start_time).format('HH:mm') }〜{ moment(schedule.learning_date + ' ' + schedule.end_time).format('HH:mm') }</td>
          <td>
          <button onClick={() => { setSalesId(schedule.sales_id); setScheduleId(schedule.schedule_id); setScheduleDate(moment(schedule.lesson_date).format('YYYY年MM月DD日') + ' ' + moment(schedule.lesson_start).format('HH:mm') + '-' + moment(schedule.lesson_end).format('HH:mm')); setIsOpenAgree(true);}} className={ 'btn-status in' } style={{ display: (!schedule.sign && schedule.schedule_id) ? 'initial' : 'none' }}>サイン</button>
          <button className={ 'btn-status sign' } style={{ display: (schedule.sign && false) ? 'initial' : 'none' }}>サイン</button>
          </td>
        </tr>
        )) }
      </tbody>
    </table>
  </div>
  </div>


  <Modal isOpen={modalIsOpenAgree} style={modalStyleAgree}>
      <button className={ 'btn-close' } onClick={() => { setLines([]); setIsOpenAgree(false);} }>×</button>
      <div>
          <p>{ student.sname }様</p><p>{ scheduleDate }</p>
        </div>
      <div>
      <>
          <FlexDiv>
            <StageDiv>
              <Stage
                width={300}
                height={350}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                onMousemove={handleMouseMove}
                onTouchMove={handleMouseMove}
                onMouseup={handleMouseUp}
                onTouchEnd={handleMouseUp}
                style={{
                  border: "1px solid",
                  marginTop: "30px"
                }}
                ref={stageRef}
              >
                <Layer>
                  {lines.map((line, i) => (
                    <Line
                      key={i}
                      points={line.points}
                      stroke={line.color}
                      strokeWidth={line.size}
                      tension={0.5}
                      lineCap="round"
                      globalCompositeOperation={
                        line.tool === "eraser" ? "destination-out" : "source-over"
                      }
                    />
                  ))}
                </Layer>
              </Stage>
            </StageDiv>
            
          </FlexDiv>
        </>
        </div>
        <button
        id="save"
        onClick={() => {
          setAttendanceAgree(scheduleId,salesId,stageRef.current.toDataURL());
          //console.log(stageRef.current.toDataURL());
          /*
          downloadURI(
            stageRef.current
              .getStage()
              .toDataURL({ mimeType: "image/png", quality: 1.0 }),
            "export_" + formatDate(new Date(), "yyyyMMddHHmmssSSS") + ".png"
          );*/ setLines([]); setIsOpenAgree(false);
        }}
        className="btn-in">承認</button>
        </Modal>


        </div>
    )
}

export default Applied

const FlexDiv = styled.div`
  display: flex;
`;

const StageDiv = styled.div`
  background-color: #fff;
  width: 300px;
  height: 390px;
  margin: 0px auto 5px auto;
`;