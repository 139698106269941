/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSchedules = /* GraphQL */ `
  query GetSchedules($id: ID!) {
    getSchedules(id: $id) {
      id
      tcode
      scode
      description
      memo
      lesson_date
      lesson_start
      lesson_end
      day
      start
      end
      start_date
      status
      owner
      signature
      createdAt
      updatedAt
    }
  }
`;
export const listScheduless = /* GraphQL */ `
  query ListScheduless(
    $filter: ModelSchedulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduless(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tcode
        scode
        description
        memo
        lesson_date
        lesson_start
        lesson_end
        day
        start
        end
        start_date
        status
        owner
        signature
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($id: ID!) {
    getMessages(id: $id) {
      id
      tcode
      scode
      messages
      status
      owner
      signature
      createdAt
      updatedAt
    }
  }
`;
export const listMessagess = /* GraphQL */ `
  query ListMessagess(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessagess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tcode
        scode
        messages
        status
        owner
        signature
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPostsSortByStartDate = /* GraphQL */ `
  query ListPostsSortByStartDate(
    $tcode: Int
    $start_date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSchedulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsSortByStartDate(
      tcode: $tcode
      start_date: $start_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tcode
        scode
        description
        memo
        lesson_date
        lesson_start
        lesson_end
        day
        start
        end
        start_date
        status
        owner
        signature
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchScheduless = /* GraphQL */ `
  query SearchScheduless(
    $filter: SearchableSchedulesFilterInput
    $sort: SearchableSchedulesSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchScheduless(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tcode
        scode
        description
        memo
        lesson_date
        lesson_start
        lesson_end
        day
        start
        end
        start_date
        status
        owner
        signature
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchMessagess = /* GraphQL */ `
  query SearchMessagess(
    $filter: SearchableMessagesFilterInput
    $sort: SearchableMessagesSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMessagess(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tcode
        scode
        messages
        status
        owner
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
