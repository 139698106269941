import React, {useState, useEffect} from 'react';
//import ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';

import moment from 'moment';

import { API, graphqlOperation } from 'aws-amplify';
import { listPostsSortByStartDate } from '../graphql/queries';

//import Navbar from './Navbar';

Amplify.configure(awsconfig);

function Index() {

   const [schedules, setSchedules] = useState([]);
   const month = parseInt(moment().format('YYYYMM'));

   const [startDate, setStartDate] = useState(new Date());

   //const targetMonth = useRef(null);

    useEffect(() => {
      async function fetchScheduleAsync() {
        const tcode = document.getElementById('teacher').dataset.tcode;
        const results = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode}, {
          filter: {month: {eq: month}}
        ,limit: 5000})));
        
        /*
        let results3 = null;
        if (results.data.listPostsSortByStartDate.nextToken) {
          const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
            filter: {month: {eq: month}}
          ,limit: 5000, nextToken: results.data.listPostsSortByStartDate.nextToken})));

          results3 = results.data.listPostsSortByStartDate.items.concat(results2.data.listPostsSortByStartDate.items);
        }
*/
        let results3 = results.data.listPostsSortByStartDate.items;
        //filter: {scode: {eq: scode}, month: {eq: month}}
        let nextToken = results.data.listPostsSortByStartDate.nextToken;
        while(nextToken) {
          const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
            filter: {month: {eq: month}}
          , nextToken: nextToken})));
  
          results3 = results3.concat(results2.data.listPostsSortByStartDate.items);

          nextToken = results2.data.listPostsSortByStartDate.nextToken;
        }
        
        if (results3) {
          results.data.listPostsSortByStartDate.items = results3;
        }

        setSchedules(results.data.listPostsSortByStartDate.items.filter((_, i) => i !== results.data.listPostsSortByStartDate.items.length));
      }
      fetchScheduleAsync();
    },[month]);

    async function addMonth(i) {
      const tMonth = parseInt(moment(i).format('YYYYMM'));
      //setStartDate(date);
      const tcode = document.getElementById('teacher').dataset.tcode;
        const results = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode}, {
          filter: {month: {eq: tMonth}}
        ,limit: 5000})));

        let results3 = results.data.listPostsSortByStartDate.items;
        //filter: {scode: {eq: scode}, month: {eq: month}}
        let nextToken = results.data.listPostsSortByStartDate.nextToken;
        while(nextToken) {
          const results2 = await API.graphql(graphqlOperation(listPostsSortByStartDate, Object.assign({tcode: tcode},{
            filter: {month: {eq: month}}
          , nextToken: nextToken})));
  
          results3 = results3.concat(results2.data.listPostsSortByStartDate.items);

          nextToken = results2.data.listPostsSortByStartDate.nextToken;
        }
        
        if (results3) {
          results.data.listPostsSortByStartDate.items = results3;
        }

        setSchedules(results.data.listPostsSortByStartDate.items.filter((_, i) => i !== results.data.listPostsSortByStartDate.items.length));
    }
  
    return (
        <div>
            <ul className={ 'menu-list' }>
        <li><a href="/" className={ 'active' }>予定一覧</a></li>
              <li><a href="/students">担当生徒</a></li>
            </ul>
        <div className="wrapper">
            
            
  <div className="separate">
    <h2>授業予定一覧</h2>
  </div>
  <div className="tableContainer -personal">
  <div className="date-picker">
    <DatePicker
      selected={startDate}
      onChange={(date) => {setStartDate(date); addMonth(date);}}
      dateFormat="yyyy/MM"
      showMonthYearPicker
    /></div>
    <table>
      <thead>
        <tr>
          <th>授業日</th>
          <th>時間</th>
          <th>ステータス</th>
        </tr>
      </thead>
      <tbody>
        { schedules.map((schedule) => (
          <tr key={schedule.id}>
          <td>{ moment(schedule.lesson_start).format('YYYY年MM月DD日') }</td>
          <td>{ moment(schedule.lesson_start).format('HH:mm') }〜{ moment(schedule.lesson_end).format('HH:mm') }</td>
          <td>
            <a href={'/student/' + schedule.scode }><button className={ 'btn-status' } style={{ display: schedule.status===0 ? 'initial' : 'none' }}>予約</button></a>
            <a href={'/student/' + schedule.scode }><button className={ 'btn-status out' } style={{ display: schedule.status===1 ? 'initial' : 'none' }}>完了</button></a>
            <a href={'/student/' + schedule.scode }><button className={ 'btn-status in' } style={{ display: schedule.status===2 ? 'initial' : 'none' }}>承認済</button></a>
          </td>
        </tr>
        )) }
      </tbody>
    </table>
  </div>
  </div>


        </div>
    )
}

export default Index