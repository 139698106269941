import React, {useEffect, useState, useRef} from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { createSchedules } from '../graphql/mutations';

import moment from 'moment';

import {
  useParams
} from 'react-router-dom';

//import Navbar from './Navbar';


function Schedule() {
  const { scode } = useParams();

  const createScheduleInput = {
    tcode: null,
    scode: scode,
    status: 0,
    memo: '',
    description: '',
    lesson_date: null,
    lesson_start: null,
    lesson_end: null,
    start_date: null
  }

  const day = useRef(null);
  const start_h = useRef(null);
  const start_m = useRef(null);
  const end_h = useRef(null);
  const end_m = useRef(null);
  const term = useRef(null);

  async function createSchedule() {
    const user = await Auth.currentAuthenticatedUser();
    createScheduleInput['tcode'] = user.attributes['custom:tcode'];
    createScheduleInput['lesson_date'] = day.current.value;
    createScheduleInput['lesson_start'] = day.current.value + ' ' + start_h.current.value + ':' + start_m.current.value;
    createScheduleInput['lesson_end'] = day.current.value + ' ' + end_h.current.value+ ':' + end_m.current.value;
    createScheduleInput['start_date'] = parseInt(moment(createScheduleInput['lesson_start']).format('YYYYMMDDHH'));

    createScheduleInput['month'] = parseInt(moment(createScheduleInput['lesson_start']).format('YYYYMM'));
    createScheduleInput['day'] = parseInt(moment(createScheduleInput['lesson_start']).format('DD'));
    createScheduleInput['start'] = parseInt(moment(createScheduleInput['lesson_start']).format('HHmm'));
    createScheduleInput['end'] = parseInt(moment(createScheduleInput['lesson_end']).format('HHmm'));
    //createScheduleInput['start_time'] = parseInt(moment(createScheduleInput['lesson_start']).format('HHmm'));
    await API.graphql(graphqlOperation(createSchedules, {input: createScheduleInput}));
    window.location.href = "/student/" + scode;
    //console.log(schedules);
  }

  function addEndTime() {
    end_h.current.value = moment(day.current.value + ' ' + start_h.current.value + ':' + start_m.current.value).add(term.current.value, "m").format("HH");
    end_m.current.value = moment(day.current.value + ' ' + start_h.current.value + ':' + start_m.current.value).add(term.current.value, "m").format("mm");
  }

const [student, setStudent] = useState([]);
  useEffect(() => {
    day.current.value = moment().format('YYYY-MM-DD');
    async function fetchScheduleAsync() {
      const user = await Auth.currentAuthenticatedUser();
      const tcode = user.attributes['custom:tcode'];

      let apiName = 'jd';
      let path = '/sales';
      let myInit = {
        headers: {},
        response: true,
        queryStringParameters: {
          "tcode": tcode,
          "scode": scode
        }
      }
      const resultsScode = await API.get(apiName, path, myInit);   
      setStudent(resultsScode.data.Students[0]);
    }
    fetchScheduleAsync();

  },[scode]);

    return (
        <div>
          <ul className={ 'menu-list' }>
        <li><a href="/">予定一覧</a></li>
              <li><a href="/students">担当生徒</a></li>
              <li><a href={ '/student/' + scode }>{ student.sname }</a></li>
            </ul>
        <div className="wrapper">

  <div className="separate">
    <h2>授業登録</h2>
  </div>
  <div className="tableContainer -input">
    <div className="inputWrapper">
      <p>授業日</p>
      <input ref={ day } type="date" />
    </div>

    <div className="inputWrapper">
      <p>開始時間</p>
      <select ref={start_h} className={ 'time_h' }>
        <option value="06">06</option>
        <option value="07">07</option>
        <option value="08">08</option>
        <option value="09">09</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
      </select>：<select ref={start_m} className={ 'time_m' }>
        <option value="00">00</option>
        <option value="05">05</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="30">30</option>
        <option value="35">35</option>
        <option value="40">40</option>
        <option value="45">45</option>
        <option value="50">50</option>
        <option value="55">55</option>
      </select>
    </div>

    <div className="inputWrapper">
      <p>授業時間</p>
      <select onChange={() => addEndTime()} ref={term} className={ 'time_t' }>
        <option>--</option>
        <option value="60">60分</option>
        <option value="90">90分</option>
        <option value="120">120分</option>
        <option value="150">150分</option>
        <option value="180">180分</option>
        <option value="210">210分</option>
        <option value="240">240分</option>
        <option value="270">270分</option>
        <option value="300">300分</option>
      </select>
    </div>

    <div className="inputWrapper">
      <p>終了時間</p>
      <select ref={end_h} className={ 'time_h' }>
        <option value="06">06</option>
        <option value="07">07</option>
        <option value="08">08</option>
        <option value="09">09</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
      </select>：<select ref={end_m} className={ 'time_m' }>
        <option value="00">00</option>
        <option value="05">05</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="30">30</option>
        <option value="35">35</option>
        <option value="40">40</option>
        <option value="45">45</option>
        <option value="50">50</option>
        <option value="55">55</option>
      </select>
    </div>

    <div className="buttonArea -between">
      <div className="btn -save" onClick={() => createSchedule()}>登録</div>
    </div>
    </div>
  </div></div>

    )
}

export default Schedule
