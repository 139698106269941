import React, {useEffect, useState} from 'react';
//import React from 'react';
import Amplify, { API, Auth } from 'aws-amplify';

import awsconfig from '../aws-exports';

//import { listSchedules } from '../graphql/queries';

Amplify.configure(awsconfig);

//import Navbar from './Navbar';

function Students() {

  const [students, setStudents] = useState([]);
  //const [currentUser, setCurrentUser] = React.useState("");
    useEffect(() => {      
      let apiName = 'jd';
      let path = '/sales';
      
      async function fetchStudentsAsync() {
        const user = await Auth.currentAuthenticatedUser();
        let myInit = {
          headers: {},
          response: true,
          queryStringParameters: {
            "tcode": user.attributes['custom:tcode'],
            "scode": ""
          }
        }
        const results = await API.get(apiName, path, myInit);
        if (results.data.tcode === user.attributes['custom:tcode']) {
          //setCurrentUser(user.attributes);
          setStudents(results.data.Students);
        }
      }
      fetchStudentsAsync();
    },[]);

    return (
      <div>
        <ul className={ 'menu-list' }>
        <li><a href="/">予定一覧</a></li>
              <li><a href="/students" className={ 'active' }>担当生徒</a></li>
            </ul>
        <div className="wrapper">
        <h2>担当生徒</h2>
        <div className="tableContainer -nameList">
          <table>
            <thead>
              <tr>
                <th>氏名（かな）</th>
              </tr>
            </thead>
            <tbody>
        { students.map((student) => (
          <tr key={student.scode}>
          <td><a href={'/student/' + student.scode }>{ student.sname }<br />{ student.furi }</a></td>
        </tr>
        )) }
      </tbody>
          </table>
        </div>
        </div>
      </div>
    )
}

export default Students
