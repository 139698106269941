import React from 'react';
import { Amplify, Auth, I18n} from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn, AmplifyForgotPassword } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Manual from './components/SystemManual';
import Applied from './components/Applied';
import Schedule from './components/Schedule';
import Students from './components/Students';
import Student from './components/Student';
import Home from './components/Index';

Amplify.configure(awsconfig);

const dict = {
  ja: {
    "Forgot your password?": "パスワードを忘れた場合",
    "Reset password": "パスワードをリセット",
    "No account?": "アカウントを持っていない場合",
    "Create account": "サインアップ",
    "Back to Sign In": "サインインに戻る",
    "Username *": "ユーザ名 *",
    "Enter your username": "ユーザ名を入力",
    "Confirmation Code": "確認コード",
    "Enter your code": "確認コードを入力",
    "Lost your code?": "確認コードが届かない場合",
    "Resend Code": "再送する",
    "Verification code": "検証コード",
    "Enter code": "検証コードを入力",
    "New password": "新しいパスワード",
    "Enter your new password": "新しいパスワードを入力",
  },
};

I18n.putVocabularies(dict);
I18n.setLanguage("ja");

const App = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    async function logout() {
      await Auth.signOut();
      window.location.href = "/";
    }

  return authState === AuthState.SignedIn && user ? (
      <div className="App">
          <div className="header">
            <div className="header_wrapper">
                <div className="logo"><a href="/">JUKEN DOCTOR</a></div>
                <div id="teacher" data-tcode={ user.attributes['custom:tcode'] }><a href="/manual">使い方</a></div>
                <button onClick={() => logout()}>サインアウト</button>
            </div>
            
        </div>
        <Router>
            <Route exact path='/' component={Home}/>
            <Route path='/students' component={Students}/>
            <Route path='/applied/:scode' component={Applied}/>
            <Route path='/student/:scode' component={Student}/>
            <Route path='/schedule/:scode' component={Schedule}/>
            <Route path='/manual' component={Manual}/>
        </Router>
      </div>
    ) : (
      <AmplifyAuthenticator>
        <AmplifySignIn
        hideSignUp={true}
        hideForgotPassword={true}
        slot="sign-in"
        headerText="ログイン"
        submitButtonText="ログイン"
        usernameAlias="email"
        formFields={[
          {
            type: "username",
            label: "メールアドレス *",
            placeholder: "メールアドレスを入力",
            required: true,
          },
          {
            type: "password",
            label: "パスワード *",
            placeholder: "パスワードを入力",
            required: true,
          },
        ]}
      />
      <AmplifyForgotPassword
        slot="forgot-password"
        headerText="パスワードを忘れた"
        usernameAlias="email"
        formFields={[
          {
            type: "username",
            label: "メールアドレスを入力してください",
            placeholder: "メールアドレス",
          },
        ]}
        sendButtonText="送信"
        submitButtonText="送信"
      />
      </AmplifyAuthenticator>
  );
}

export default App;